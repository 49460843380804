@media screen and (max-width: 1200px) {
  .jotform-feedback-link {
    margin-right: -2rem;
    color: white !important;
  }
}

.jotform-feedback-link {
  color: white !important;
}

html {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 !important;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
}

a, a:visited, a:active {
  text-decoration: none !important;
  color: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
input:-webkit-autofill:active::first-line{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}