:root {
  --toastify-color-success: #007EA7;
}

@media screen and (max-width: 960px) {
  .toast-container {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .toast-container {
    width: 60%;
    font-size: 12px;
    position: absolute;
    margin-left: 25%;
  }
}

.toast-container {
  font-family: 'Roboto', sans-serif;
  z-index: 1999;
  margin-top: 3%;
}